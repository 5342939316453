import request from '@axios'

// 上傳

export const updateQuesDetailApi = (formData, appId) => new Promise((resolve, reject) => {
  request.post('/knowService/knowledge/document/fix/add', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-App-Uid': appId,
      'X-Appid': appId,
    },
  }).then(res => {
    console.log('111', res)
    if (res.data && (res.data.code === 200 || res.data.code === 0)) {
      resolve(res.data)
    } else {
      reject(res.data ? { code: res.data.code, message: res.data.message } : { code: res.status, message: res.statusText })
    }
  }).catch(err => {
    reject({ code: err.response.status, message: err.response.statusText })
  })
})

// 详情
export const getQuesDetailApi = params => (
  new Promise((resolve, reject) => {
    request.get('/knowService/knowledge/document/fix/get', {
      params,
      headers: {
        'X-App-Uid': params.app_uid,
        'X-Appid': params.app_uid,
      },
    }).then(res => {
      console.log('sssssd', res)
      if (res.data && (res.data.code === 200 || res.data.code === 0)) {
        resolve(res.data)
      } else {
        reject(res.data ? { code: res.data.code, message: res.data.message } : { code: res.status, message: res.statusText })
      }
    }).catch(err => {
      console.log('123', err)
      reject({ code: err.response.status, message: err.response.statusText })
    })
  })
)

// 删除
export const deleteQuesApi = params => (
  new Promise((resolve, reject) => {
    request.delete('/knowService/knowledge/document/fix/delete', {
      params,
      headers: {
        'X-App-Uid': params.appId,
        'X-Appid': params.appId,
      },
    }).then(res => {
      if (res.data && (res.data.code === 200 || res.data.code === 0)) {
        resolve(res.data)
      } else {
        reject(res.data ? { code: res.data.code, message: res.data.message } : { code: res.status, message: res.statusText })
      }
    }).catch(err => {
      reject({ code: err.response.status, message: err.response.statusText })
    })
  })
)
export default {}
