import request from '@axios'
import axios from 'axios'

export const goToLogin = params => (
  new Promise((resolve, reject) => {
    axios.post('/dataService/admin/v1/user/login', params).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log('eee', err)
      reject({ code: err.response.status, message: err.response.statusText })
    })
  })
)

export const getEnvs = () => new Promise((resolve, reject) => {
  if (sessionStorage.getItem('envs')) {
    resolve(JSON.parse(sessionStorage.getItem('envs') || '{}'))
  } else {
    axios.get('/botService/v1/ui/envs').then(res => {
      if (res.status === 200 && res?.data.status === 0) {
        sessionStorage.setItem('envs', JSON.stringify(res.data.data))
        resolve(res.data.data)
      }
    })
  }
})
export const fetchRobotList = params => (
  new Promise((resolve, reject) => {
    request.post('/botService/v1/robots/page', params).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject({ code: err.response.status, message: err.response.statusText })
    })
  })
)

export const getEnterpriseListApi = () => (
  new Promise((resolve, reject) => {
    console.log(11, request.get)
    request.get('/botService/v1/tenants/').then(res => {
      if (res.data && (res.data.code === 200 || res.data.code === 0)) {
        resolve(res.data)
      } else {
        reject(res.data ? { code: res.data.code, message: res.data.message } : { code: res.status, message: res.statusText })
      }
    }).catch(err => {
      console.log('err', err)
      reject({ code: err.response.status, message: err.response.statusText })
    })
  })
)
export const getRobotListApi = enterprise => (
  new Promise((resolve, reject) => {
    request.get(`/botService/v1/tenant/robots/${enterprise}`).then(res => {
      if (res.data && (res.data.code === 200 || res.data.code === 0)) {
        resolve(res.data)
      } else {
        reject(res.data ? { code: res.data.code, message: res.data.message } : { code: res.status, message: res.statusText })
      }
    }).catch(err => {
      reject({ code: err.response.status, message: err.response.statusText })
    })
  })
)
