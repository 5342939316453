import { fileDownload } from '@/plugins/utils'
import axios from 'axios'

export const downloadFile = params => (
  new Promise((resolve, reject) => {
    axios.get('/dataService/admin/chat_history/export/', {
      params,
      headers: {
        'X-Api-Key': '2143b9c004f511ee9694acde48001122',
        token: localStorage.getItem('kkbotDataToken'),
      },
      responseType: 'arraybuffer',
    }).then(res => {
      console.log('1111111111111', res)
      if (res.status === 200) {
        const bolb = res.data
        const filename = res.headers['content-disposition'].split('filename=')[1]
        fileDownload(bolb, filename)
        resolve(res)
      } else {
        reject(res)
      }
    }).catch(err => {
      reject({ code: 500, message: err.response.statusText })
    })
  })
)

export default {}
